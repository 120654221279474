<template>
  <div class="list-user">
    <CCard
      style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); border-radius: revert"
    >
      <div class="d-flex align-items-center">
        <span class="font-title cpx-4 cpy-2">ショップ設定</span>
      </div>
      <CCardBody class="p-0">
        <div>
          <label class="font-weight-bold cp-label">
            デフォルトのカテゴリー順序
          </label>
          <draggable v-model="listCategory" class="cp-4">
            <!-- <transition-group> -->
            <div
              v-for="(value, index) in listCategory"
              :key="value.id"
              class="d-flex mt-3"
            >
              <b-button
                class="bg-00b797 text-white"
                @click="removeTypeDisplay(index)"
                style="width: 38px; margin-right: 10px"
              >
                <font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'minus']"
                />
              </b-button>
              <b-form-select
                @change="handleChange"
                v-model="value.value"
                :options="listAllCategory"
              >
              </b-form-select>
            </div>
            <!-- </transition-group> -->
          </draggable>
          <b-button
            v-if="listCategory < listAllCategory"
            class="mt-3 bg-00b797 text-white"
            style="margin-left: 48px; border-radius: 50% !important"
            @click="addCategory()"
          >
            <font-awesome-icon class="sidebar-icon" :icon="['fas', 'plus']" />
          </b-button>
          <div class="mb-3 mt-3 row-input" style="margin-left: 48px">
            <b-form-group v-slot="{}">
              <b-form-checkbox
                v-model="isRandomCategory"
                id="random-category"
                name="random-category"
                value="1"
                unchecked-value="0"
                >その他カテゴリはランダム配置する</b-form-checkbox
              >
            </b-form-group>
          </div>
        </div>
        <div v-if="userProfile && userProfile.user_type === 0">
          <b-form-group v-slot="{ ariaDescribedby1 }">
            <label class="font-weight-bold cp-label"
              >ウィジェット配置設定</label
            >
            <div class="cp-4">
              <div class="d-flex">
                <div class="mr-1 text-center">
                  <b-form-radio
                    v-model="settingWidget"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby1"
                    name="setting-widget"
                    :value="0"
                    id="radio_widget_1"
                    @change="settingWidget = 0"
                    >指定</b-form-radio
                  >
                  <label
                    for="radio_widget_1"
                    style="width: 130px"
                    :class="
                      settingWidget === 0
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    指定
                  </label>
                </div>
                <div class="ml-1 text-center">
                  <b-form-radio
                    v-model="settingWidget"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby1"
                    name="setting-widget"
                    :value="1"
                    @change="settingWidget = 1"
                    id="radio_widget_2"
                    >順番
                  </b-form-radio>
                  <label
                    for="radio_widget_2"
                    style="width: 130px"
                    :class="
                      settingWidget === 1
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    順番
                  </label>
                </div>
              </div>

              <div
                v-if="settingWidget == 0"
                class="row-input d-flex align-items-center pt-3"
              >
                <span>ウィジェットを選択:</span>
                <b-form-select
                  class="w-25 ml-2"
                  v-model="optionWidget"
                  :options="[1, 2, 3, 4]"
                >
                </b-form-select>
              </div>
            </div>
          </b-form-group>

          <label class="font-weight-bold cp-label">配置条件</label>
          <div class="row-input d-flex align-items-center cp-4">
            <span>ウィジェット</span>
            <CInput
              class="mb-0 mx-2"
              v-model="numberAdvertisement"
              type="number"
              style="width: 100px"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
            <span>毎配置</span>
          </div>

          <label class="font-weight-bold cp-label">広告画像設定</label>
          <div class="pr-0">
            <draggable v-model="listAdvertisement" class="cp-4">
              <!-- <transition-group> -->
              <div
                v-for="(
                  valueAdvertisement, indexAdvertisement
                ) in listAdvertisement"
                :key="indexAdvertisement"
                class="d-flex mt-3"
              >
                <b-button
                  class="bg-00b797 text-white"
                  @click="removeListAdvertisement(indexAdvertisement)"
                  style="width: 38px; height: 35px; margin-right: 10px"
                >
                  <font-awesome-icon
                    class="sidebar-icon"
                    :icon="['fas', 'minus']"
                  />
                </b-button>
                <div>
                  <div>
                    <label
                      class="btn-upload-image text-center"
                      variant="secondary"
                      v-on:click.prevent="uploadPre(indexAdvertisement)"
                    >
                      画像追加
                    </label>
                    <input
                      id="upload"
                      type="file"
                      class="d-none"
                      accept="image/jpg, image/png, image/jpeg"
                      @change="uploadImg($event, indexAdvertisement)"
                    />
                  </div>
                  <div class="preview-img" v-if="valueAdvertisement.url_image">
                    <img
                      :src="`${urlBackend}/${valueAdvertisement.url_image}`"
                    />
                  </div>
                </div>
                <div class="w-50 mx-2 mb-0 d-flex">
                  <CInput
                    class="mb-0 mx-2 w-50"
                    v-model="valueAdvertisement.url"
                    type="text"
                  />
                  <font-awesome-icon
                    class="sidebar-icon"
                    style="
                      font-size: 1.25rem;
                      margin-left: 5px;
                      margin-top: 8px;
                      transform: rotate(90deg);
                      cursor: grabbing;
                    "
                    :icon="['fas', 'bars']"
                  />
                </div>
              </div>
              <!-- </transition-group> -->
            </draggable>
            <b-button
              class="mt-3 bg-00b797 text-white"
              style="margin-left: 48px; border-radius: 50% !important"
              @click="addAdvertisement()"
            >
              <font-awesome-icon class="sidebar-icon" :icon="['fas', 'plus']" />
            </b-button>
            <b-form-group
              class="col-sm-12 pl-0 mt-3"
              v-slot="{ ariaDescribedby }"
            >
              <label class="font-weight-bold cp-label">自動配置設定</label>
              <div class="d-flex cp-4">
                <div class="mx-1 text-center">
                  <b-form-radio
                    v-model="settingAuto"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="setting-auto"
                    :value="0"
                    id="radio_1"
                    @change="settingAuto = 0"
                    >設定しない</b-form-radio
                  >
                  <label
                    for="radio_1"
                    style="width: 130px"
                    :class="
                      settingAuto === 0
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    設定しない
                  </label>
                </div>
                <div class="mx-1 text-center">
                  <b-form-radio
                    v-model="settingAuto"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="setting-auto"
                    :value="1"
                    @change="settingAuto = 1"
                    id="radio_2"
                    >設定する
                  </b-form-radio>
                  <label
                    for="radio_2"
                    style="width: 130px"
                    :class="
                      settingAuto === 1
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    設定する
                  </label>
                </div>
              </div>
            </b-form-group>
            <div v-if="settingAuto === 1">
              <b-form-group class="col-sm-12 pl-0" v-slot="{ ariaDescribedby }">
                <label class="col-sm-12 font-weight-bold cp-label">
                  カテゴリー自動配置</label
                >
                <div class="d-flex cp-4">
                  <b-form-radio
                    v-model="typeSortCategory"
                    class="mr-3 d-none"
                    id="type-sort-category_0"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-category"
                    :value="0"
                    >ポイントの合計</b-form-radio
                  >
                  <label
                    for="type-sort-category_0"
                    :class="
                      typeSortCategory === 0
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    ポイントの合計
                  </label>
                  <b-form-radio
                    v-model="typeSortCategory"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-category"
                    id="type-sort-category_1"
                    :value="1"
                    >クリック数のみ
                  </b-form-radio>
                  <label
                    for="type-sort-category_1"
                    :class="
                      typeSortCategory === 1
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    クリック数のみ
                  </label>
                  <b-form-radio
                    v-model="typeSortCategory"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-category"
                    id="type-sort-category_2"
                    :value="2"
                    >購入数のみ
                  </b-form-radio>
                  <label
                    for="type-sort-category_2"
                    :class="
                      typeSortCategory === 2
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    購入数のみ
                  </label>
                </div>
              </b-form-group>
              <b-form-group
                class="col-sm-12 pl-0 mt-3"
                v-slot="{ ariaDescribedby }"
              >
                <label class="col-sm-12 font-weight-bold cp-label"
                  >カテゴリー内コンテンツの自動配置</label
                >
                <div class="d-flex cp-4">
                  <b-form-radio
                    v-model="typeSortContent"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-content"
                    id="type-sort-content_0"
                    :value="0"
                    >ポイントの合計</b-form-radio
                  >
                  <label
                    for="type-sort-content_0"
                    :class="
                      typeSortContent === 0
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    ポイントの合計
                  </label>
                  <b-form-radio
                    v-model="typeSortContent"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-content"
                    id="type-sort-content_1"
                    :value="1"
                    >クリック数のみ
                  </b-form-radio>
                  <label
                    for="type-sort-content_1"
                    :class="
                      typeSortContent === 1
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    クリック数のみ
                  </label>
                  <b-form-radio
                    v-model="typeSortContent"
                    class="mr-3 d-none"
                    :aria-describedby="ariaDescribedby"
                    name="type-sort-content_2"
                    id="type-sort-content_2"
                    :value="2"
                    >購入数のみ
                  </b-form-radio>
                  <label
                    for="type-sort-content_2"
                    :class="
                      typeSortContent === 2
                        ? 'btn-share-freesize-active py-2 px-4 mr-3'
                        : 'btn-share-freesize py-2 px-4 mr-3'
                    "
                  >
                    購入数のみ
                  </label>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-4">
          <button
            :disabled="isLoading"
            variant="success"
            class="mr-4 btn-save"
            @click="saveSettingStore()"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            更新する
          </button>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants.js";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "SettingStore",
  components: {
    draggable,
  },
  data() {
    return {
      radioStatus: 0,
      urlBackend: Constants.URL_BE,
      listPageDefault: [
        [{ label: "商品画像とタイトル表示", value: 0 }],
        [{ label: "目次表示", value: 0 }],
        [{ label: "紹介ビデオ", value: 0 }],
        [{ label: "制作者情報", value: 0 }],
      ],
      page: [{ label: "１カラムページのタイトルを検索及び選択", value: 0 }],
      typeDisplay: "",
      typeDisplayRedirect: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listAllCategory: [
        {
          value: 0,
          text: "●●さんへのおすすめ（消さないことをオススメします）",
          id: "recommend",
        },
      ],
      listCategory: [],
      listCategoryTemp: [],
      listCategoryDictionary: {},
      isRandomCategory: false,
      numberAdvertisement: null,
      listAdvertisement: [{ img: "", url: "", url_image: "", id: 0 }],
      settingAuto: 0,
      typeSortCategory: 0,
      typeSortContent: 0,
      isLoading: false,
      userProfile: null,
      listCategoryRequest: null,
      indAdv: null,
      settingWidget: 0,
      optionWidget: 1,
    };
  },
  async created() {
    const request = {
      shop_id: this.shop_id,
    };
    await this.getListCategoriesInShop(request);
    this.getProfile();
    this.getSettingStore();
  },
  computed: {
    ...mapGetters(["success", "message", "error", "listCategoriesInShop"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listCategoriesInShop() {
      let listCategoryTemp = this.listCategoriesInShop.map((item) => ({
        text: item.category_name,
        value: item.id.toString(),
        id: item.id,
      }));
      this.listAllCategory = this.listAllCategory.concat(listCategoryTemp);
      for (let element of this.listAllCategory) {
        this.listCategoryDictionary[element.value] = element.text;
      }
    },
  },
  methods: {
    ...mapActions({ updateUserOwner: "updateUserOwner" }),
    ...mapActions({ getListCategoriesInShop: "getListCategoriesInShop" }),
    saveSettingStore() {
      let {
        shop_id,
        listCategory,
        isRandomCategory,
        numberAdvertisement,
        listAdvertisement,
        settingAuto,
        typeSortCategory,
        typeSortContent,
        settingWidget,
        optionWidget,
      } = this;

      //Check if any value is null:
      if (listCategory.some((element) => element.value === null)) {
        this.$toasted.error("デフォルトのカテゴリを空白にすることはできません");
        return;
      }
      /*=========================*/
      //Check if duplicate:
      let set = new Set();
      for (let element of listCategory) {
        if (set.has(element.value)) {
          this.$toasted.error("デフォルトのカテゴリは重複してはいけません");
          return;
        }
        set.add(element.value);
      }
      /*=========================*/
      this.parseListCategory();
      listCategory = this.listCategory;
      let error;
      if (this.userProfile && this.userProfile.user_type === 0) {
        listAdvertisement.forEach((element) => {
          if (!element.url.match(Constants.REGEX_URL)) {
            error = true;
            this.$toasted.error("URLの形式が正しくありません");
            return;
          }
        });
      }
      let listCategoryRequest = listCategory.map(function (item) {
        if (item.id == 0) return "recommend";
        else return item.id;
      });
      if (!error) {
        const reg = {
          shop_id: shop_id,
          json_category: listCategoryRequest,
          is_random: isRandomCategory,
          advertising_frequency: numberAdvertisement,
          json_url: listAdvertisement,
          automatic_sort: settingAuto,
          automatic_sort_category: typeSortCategory,
          automatic_sort_content: typeSortContent,
          setting_widget: settingWidget,
          option_widget: optionWidget,
        };
        const url = `/${Urls.SETTING}/${Urls.STORE}`;
        Api.adminRequestServer
          .post(url, reg)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$toasted.success(data.message);
              if (this.$route.params.shopId) {
                this.$router.push({
                  name: "DashboardAdminSetting",
                  params: { shopId: this.shop_id },
                });
              } else {
                this.$router.push({
                  name: "DashboardAdminSetting domain",
                });
              }
            } else {
              this.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      }
    },
    getSettingStore() {
      const url = `/${Urls.SETTING}/${Urls.STORE}?shop_id=${this.shop_id}`;
      Api.adminRequestServer
        .get(url)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            const {
              json_category,
              is_random,
              advertising_frequency,
              json_url,
              automatic_sort,
              automatic_sort_category,
              automatic_sort_content,
              setting_widget,
              option_widget,
            } = data.data;
            this.listCategoryRequest = json_category ? json_category : [];
            if (this.listCategoryRequest.length > 0) {
              this.listCategoryRequest.forEach((id) => {
                let obj = this.listAllCategory.find((e) => e.id === id);
                if (obj) {
                  this.listCategory.push({ ...obj });
                }
              });
            } else {
              this.listCategory.push({
                value: 0,
                text: "●●さんへのおすすめ（消さないことをオススメします）",
                id: "recommend",
              });
            }
            this.isRandomCategory = is_random;
            this.numberAdvertisement = advertising_frequency;
            this.listAdvertisement = json_url ? JSON.parse(json_url) : [];
            this.settingAuto = automatic_sort;
            this.typeSortCategory = automatic_sort_category;
            this.typeSortContent = automatic_sort_content;
            this.settingWidget = setting_widget;
            this.optionWidget = option_widget;
          } else {
            this.$toasted.error(data.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$toasted.error(
              "決済時にシステムエラーが発生しました。再度お試しください。"
            );
          }
        });
    },
    getProfile() {
      const url = `/${Urls.PROFILE}?shop_id=${this.shop_id}`;
      Api.adminRequestServer
        .get(url)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.userProfile = data.data;
          }
        })
        .catch((error) => {
          this.$toasted.error(error.message);
        });
    },
    changeTypeDisplay(type) {
      this.typeDisplay = type;
    },
    changeTypeDisplayRedirect(type) {
      this.typeDisplayRedirect = type;
    },
    addPage() {
      this.listPageDefault.push({
        label: "制作者情報",
        value: "制作者情報",
      });
    },
    removePage(index) {
      this.listPageDefault.splice(index, 1);
    },
    returnScreen() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "DashboardAdminSetting",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "DashboardAdminSetting domain",
        });
      }
    },
    removeTypeDisplay(index) {
      this.listCategory.splice(index, 1);
    },
    addCategory() {
      this.listCategory.push({ id: uuidv4(), text: "", value: null });
    },
    addAdvertisement() {
      this.listAdvertisement.push({
        img: "",
        url: "",
        url_image: "",
        id:
          this.listAdvertisement.length > 0
            ? this.listAdvertisement[this.listAdvertisement.length - 1].id + 1
            : 1,
      });
    },
    removeListAdvertisement(index) {
      this.listAdvertisement.splice(index, 1);
    },
    uploadPre(index) {
      this.indAdv = index;
      document.getElementById("upload").click();
    },
    uploadImg(e, index) {
      if (
        e.target.files[0].type != "image/jpg" &&
        e.target.files[0].type != "image/png" &&
        e.target.files[0].type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
      } else if (e.target.files[0].size / 1024 / 1024 > 100) {
        this.$toasted.error(
          "アップロード可能なファイルのサイズは100MBまでです。"
        );
      } else {
        index = this.indAdv;
        var formData = new FormData();
        formData.append("image", e.target.files[0]);
        const url = `/${Urls.SETTING}/upload-image`;
        Api.adminRequestServer
          .post(url, formData)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.listAdvertisement[index].url_image = data.data;
            } else {
              this.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
        // let reader = new FileReader();
        // let vm = this;
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onload = (e) => {
        //   vm.listAdvertisement[index].url_image = e.target.result;
        // };
        // this.listAdvertisement[index].url_image = formData;
        this.listAdvertisement[index].img = URL.createObjectURL(
          e.target.files[0]
        );
      }
    },
    parseListCategory() {
      this.listCategory = this.listCategory.map((element) => {
        let new_element = { ...element };
        new_element.id = parseInt(element.value);
        new_element.text = this.listCategoryDictionary[element.value];
        return new_element;
      });
    },
    handleChange() {
      for (let i = 0; i < this.listCategory.length; i++) {
        let obj = this.listCategory[i];
        obj.text = this.listCategoryDictionary[obj.value];
      }
      for (let i = 0; i < this.listCategory.length; i++) {
        let obj = this.listCategory[i];
        obj.text = this.listCategoryDictionary[obj.value];
      }
    },
  },
};
</script>
<style lang="scss">
.btn-collapse:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid black;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transform: rotate(180deg);
  transition: all linear 0.25s;
  float: right;
  position: absolute;
  top: 30%;
  right: 10px;
}
button.btn-collapse {
  width: 500px;
  text-align: left;
  position: relative;
}
.title-setting {
  background: #ced2d8;
  height: 32px;
  display: flex;
  align-items: center;
}
.btn-upload-image {
  position: relative;
  min-width: 150px;
  background: #00b797;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  padding: 5px 10px;
  color: #fff;
  height: 35px;
  box-shadow: 0px 0px 3px #d5d5d5;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  input:hover {
    cursor: pointer;
  }
}
.preview-img {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.btn-upload-image:hover {
  box-shadow: 0px 0px 6px #d5d5d5;
}
</style>
